
<template>
  <div id="subscription-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <v-btn
          @click.prevent="AddRecordPrompt = true"
          color="#1db954"
          elevation="30"
          rounded
          class="font-size-h6 mr-3 my-3 white--text d-inline-flex align-center"
          >Create</v-btn
        >
        <v-btn
          medium
          @click.prevent="refreshPageData"
          color="#a4c639"
          elevation="30"
          rounded
          class="font-size-h6 mr-3 my-3 white--text"
          >&nbsp; Refresh &nbsp;
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>

        <v-container v-if="!StartupLoadingFlag">
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-dialog v-model="AddRecordPrompt" persistent max-width="70%">
              <lom-training-setting-create
                pageTitle="lom_training_setting"
                :AddRecordPrompt="AddRecordPrompt"
                @hideDialog="hideAddRecordPrompt"
                v-if="AddRecordPrompt"
              ></lom-training-setting-create>
            </v-dialog>
          </v-form>
        </v-container>

        <v-container class="py-0" v-if="SearchFlag && !StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container
          class="py-0"
          v-if="!SearchFlag && !StartupLoadingFlag && rows.length == 0"
        >
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="!SearchFlag && rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3 class="text-primary">{{ rows.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :headers="tableColumns1"
                :items="rows"
                :items-per-page="50"
                :search="search"
                item-key="MemberId"
                :single-select="false"
                :show-select="TableSelectFlag"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50],
                }"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.Tax="{ item }">
                  {{ item.Tax }}%
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        icon
                        size="25px"
                        color="blue"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.ActiveStatus == 2"
                      >
                        mdi-clipboard-text-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="editAlert(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteConfirmAlert(item, tr)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span> Delete </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>

    <v-dialog v-model="editRecordPrompt" persistent max-width="70%">
      <lom-training-setting-edit
        :record="rowData"
        pageTitle="lom_training_setting"
        :editRecordPrompt="editRecordPrompt"
        @hideDialog="hideEditRecordPrompt"
        :Year="JciYearCode"
        v-if="editRecordPrompt"
      ></lom-training-setting-edit>
    </v-dialog>
  </div>
</template>
  
  <script>
import LomTrainingSettingCreate from "@/view/pages/erp/lom-training-setting/LomTrainingSettingCreate.vue";
import LomTrainingSettingEdit from "@/view/pages/erp/lom-training-setting/LomTrainingSettingEdit.vue";
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    LomTrainingSettingCreate,
    LomTrainingSettingEdit,
  },

  data() {
    return {
      AddRecordPrompt: false,
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,
      TableSelectFlag: false,
      DeleteFlag: false,

      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",
      record: {},
      rowData: {},
      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      zone: {},
      lom: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      SearchId: "",
      SearchIdRules: [(v) => !!v || "Search Type is required"],
      SearchIdOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "By LOM" },
        { value: 2, text: "By Membership Id" },
      ],

      YearIdRules: [(v) => !!v || "Year is required"],
      YearId: "",
      YearIdOptions: [],
      YearIdOptionsLoading: false,

      ApprovalDescription: "",
      ApprovalDescriptionRules: [],

      PageInfo: {},
      PageTitle: "LO Training Setting",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      rowData: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log("ZoneCode=" + ZoneCode);
        this.ZoneCode = ZoneCode;

        this.pageData();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.StartupLoadingFlag = false;
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
      this.getTableRecords();
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lom_training_setting",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.StartupLoadingFlag = true;
      this.getJciYearCodeOptions();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.AddRecordPrompt = false;
      this.getTableRecords();
    },

    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      if (flag) {
        this.getTableRecords();
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    editAlert(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });
      if (n1 > 0) {
        this.rowData = tr;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one settings to edit";
        this.toast("error", message);
      }
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var YearCode = this.JciYearCode;
      console.log("YearCode=" + YearCode);

      this.rows = [];

      if (YearCode != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training-setting/lists";
        var upload = {
          UserInterface: 1,
          Year: YearCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.rows = [];
        this.SearchFlag = true;
        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            // console.log({records});
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.rows = records.TableData;
              thisIns.TableSelectFlag = records.TableSelectFlag;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.SearchFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Year should not be empty.";
        console.log("error=" + message);
      }
    },

    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      var validate2 = this.$refs.form3.validate();
      console.log("validate1=" + validate1 + "validate2=" + validate2);

      var members = this.selected;
      var n1 = members.length;
      console.log("n1=" + n1 + ", members=" + JSON.stringify(members));

      // this.rows = [];

      if (validate1 && validate2 && n1 > 0) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/training-event/lists";
        var upload = {
          UserInterface: this.SearchId,
          FromZoneCode: this.FromZoneCode,
          FromLomCode: this.FromLomCode,
          MembershipId: this.MembershipId,
          ToZoneCode: this.ToZoneCode,
          ToLomCode: this.ToLomCode,
          YearId: this.YearId,
          YearHalf: this.YearHalfId,
          ApprovalDescription: this.ApprovalDescription,
          Members: members,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1 || !validate2) {
          message += "Kindly fill the required fields";
        }
        if (n1 == 0) {
          message += "Atleast one member is required to transfer";
        }
        this.toast("error", message);
      }
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> DisplayText <b> " + tr.DisplayText + "</b>";
        htmlTxt += "<br/> Value <b> " + tr.Value + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: "Delete",
          confirmButtonColor: "#3085d6",
          cancelButtonText: "Cancel",
          cancelButtonColor: "#000000",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(tr) {
      console.log("deleteData is called");
      console.log({ tr });

      var id = tr.LomTrainingSettingId;
      console.log({ id });

      // id = 0;
      if (id > 0) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training-setting/destroy";
        var upload = {
          UserInterface: 1,
          LomTrainingSettingId: id,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.selectedData = {};
              thisIns.getTableRecords();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        var message = "Kindlly select one Ledger to delete. ";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#subscription-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
}
</style>